import 'swiper/swiper-bundle.css';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

new Swiper('.certificates-swiper-container', {
  modules: [Navigation],
  direction: 'horizontal',
  navigation: {
    nextEl: '.certificates-swiper-button-next',
    prevEl: '.certificates-swiper-button-prev',
  },
  slidesPerView: 1,
  spaceBetween: 16,
  breakpoints: {
    834: {
      slidesPerView: 2,
      spaceBetween: 24,
    },
  },
});
