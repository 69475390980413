//Генерація повідомлення для запису в студію
export const generateMessAppointment = data => {
  return `Запишіть мене будь-ласка в студію! \nІм'я: ${
    data.name
  } \nНомер телефону: ${data.phone} \nКоментар: ${
    data.comment ? data.comment : ''
  }.`;
};

//Генерація повідомлення для замовлення дзвінка
export const generateMessCall = data => {
  return `Подзвоніть мені будь-ласка! \nІм'я: ${data.name} \nНомер телефону: ${data.phone}`;
};

//Генерація повідомлення для реєстраціі на курс

export const generateMessAppointmentCourse = data => {
  return `Запишіть мене будь-ласка на проходження навчання! \nІм'я: ${
    data.name
  } \nНомер телефону: ${data.phone} \nОсвіта: ${
    data.education ? data.education : ''
  }.`;
};
